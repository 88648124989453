import { cloneDeep, defaultsDeep, uniqBy } from 'lodash'
import { ViewFilter } from '../view.model'
import { Attribute } from '../../attributes'
import { FilterOperator } from '../../../models/filter.model'
import moment from 'moment'
import { isDate } from '@evologi/shared/util-toolkit'

// Initial states

const VIEW_FILTER_INITIAL_STATE: Partial<ViewFilter> = {
  property: undefined,
  operator: FilterOperator.equal,
  value: undefined,
}

/**
 * Initialize view filter with default values
 * @param viewFilter - the partial view filter
 * @returns the complete view filter
 */
export function initViewFilter(
  viewFilter: Partial<ViewFilter> = {}
): ViewFilter {
  return defaultsDeep(cloneDeep(viewFilter), VIEW_FILTER_INITIAL_STATE)
}

/**
 * Add view filter
 * @param filters - the view filters
 * @param filter - the filter
 * @returns the filters
 */
export function addViewFilter(
  filters: ViewFilter[] | undefined,
  filter: ViewFilter
): ViewFilter[] {
  return uniqBy([...(filters || []), filter], 'property')
}

/**
 * Update view filter
 * @param filters - the view filters
 * @param filter - the filter to update
 * @returns the filters updated
 */
export function updateViewFilter(
  filters: ViewFilter[] | undefined,
  filter: ViewFilter
): ViewFilter[] | undefined {
  if (!filters) {
    return filters
  }

  return filters.map((f) => (filter.property === f.property ? filter : f))
}

/**
 * Add view attribute filter
 * @param filters - the view filters
 * @param attribute - the attribute
 * @returns the filters
 */
export function addViewAttributeFilter(
  filters: ViewFilter[] | undefined,
  attribute: Attribute,
  operator?: FilterOperator,
  value?: string | number
): ViewFilter[] {
  return uniqBy(
    [
      ...(filters || []),
      initViewFilter({
        property: `attribute_${attribute.code}`,
        operator,
        value,
      }),
    ],
    'property'
  )
}

/**
 * Remove view filter
 * @param filters - the view filters
 * @param filter - the filter
 * @returns the filters
 */
export function removeViewFilter(
  filters: ViewFilter[] | undefined,
  property: string
): ViewFilter[] | undefined {
  if (!filters) {
    return filters
  }

  filters = filters.filter((f) => f.property !== property)
  return filters.length ? filters : undefined
}

/**
 * Parse view state filter options
 * @param filters - the view filters
 * @param params - the state params
 * @returns the search params updated
 */
export function parseViewStateFiltersOptions<T>(
  filters: ViewFilter[] | undefined,
  params: T
): T {
  if (!filters) {
    return params
  }

  return filters.reduce(
    (acc, filter) => parseViewStateFilterOption<T>(filter, acc),
    params
  )
}

/**
 * Parse view state filter option
 * @param filter - the view filter
 * @param params - the state params
 * @returns the search params updated
 */
export function parseViewStateFilterOption<T>(
  filter: ViewFilter,
  params: any
): T {
  if (!filter.property) {
    return params
  }

  if (
    filter.operator !== FilterOperator.exists &&
    filter.operator !== FilterOperator.notExists &&
    filter.value === undefined
  ) {
    return params
  }

  // Attribute value
  let filterField = filter.property

  if (filterField.startsWith('attribute_')) {
    const attributeCode = filterField.replace('attribute_', '')
    filterField = `attributes.${attributeCode}`
  }

  // Check date
  let filterValue = filter.value
  if (typeof filterValue === 'string' && isDate(filterValue)) {
    filterValue = moment(filter.value).utc().toISOString()
  }

  switch (filter.operator) {
    case FilterOperator.moreThan:
    case FilterOperator.afterThan:
      params[`${filterField}:gt`] = filter.value
      break
    case FilterOperator.lessThan:
    case FilterOperator.beforeThan:
      params[`${filterField}:lt`] = filter.value
      break
    case FilterOperator.afterOrEqualThan:
    case FilterOperator.moreOrEqualThan:
      params[`${filterField}:ge`] = filter.value
      break
    case FilterOperator.beforeOrEqualThan:
    case FilterOperator.lessOrEqualThan:
      params[`${filterField}:le`] = filter.value
      break
    case FilterOperator.equal:
      params[filterField] = filter.value
      break
    case FilterOperator.notEqual:
      params[`${filterField}:ne`] = filter.value
      break
    case FilterOperator.exists:
      params[`${filterField}:ex`] = true
      break
    case FilterOperator.notExists:
      params[`${filterField}:ex`] = false
      break
    case FilterOperator.contains:
      params[`${filterField}:ct`] = filter.value
      break
    default:
      break
  }

  return params
}
