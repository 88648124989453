import { Column, Row, Workbook } from 'exceljs'
import { saveAs } from 'file-saver'

import { getProductAttributeByField } from './attribute.lib'
import { getCatalogPriceValue } from './price.lib'
import {
  Product,
  ProductFileGeneratorField,
  ProductFileGeneratorOptions,
  ProductSite,
  ProductWrapper,
  productImportSiteKeys,
  productImportWrapperKeys,
} from '../product.model'
import { CATALOG_DEFAULT_CODE } from '../../catalogs/catalog.const'
import { LOCALE_DEFAULT_CODE } from '../../../consts/locale.const'
import { FileUpdateMethod } from '../../files/file.model'
import { getCatalogValue } from '../../catalogs/catalog.lib'
import { Brand } from '../../brands/brand.model'
import { Replenishment, replenishmentsExcelKeys } from '../../replenishments'

export function generateUpdateXlsFile(
  options: ProductFileGeneratorOptions,
  catalogCode = CATALOG_DEFAULT_CODE,
  locale = LOCALE_DEFAULT_CODE,
  products?: Product[],
  brands?: Brand[],
): void {
  // Create workbook
  const workbook = new Workbook()
  if (options.tabs.includes('PRODUCTS')) {
    const worksheet = workbook.addWorksheet('prodotti')

    // Columns
    const columns: Partial<Column>[] = [
      {
        header: String(options.key),
        key: `KEY-${options.key}`,
        width: columnWidth(options.key),
      },
      ...options.fields.map((field) =>
        parseColumnField(field, catalogCode, locale),
      ),
    ]
    worksheet.columns = columns

    worksheet.getRow(1).protection = {
      locked: true,
    }
    worksheet.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'BCBCBC' },
    }
    worksheet.getRow(1).font = {
      bold: true,
    }

    // Rows
    if (products) {
      const _brands: Record<string, string> =
        brands?.reduce((acc, b) => ({ ...acc, [b._id]: b.name }), {}) || {}
      const rows = products.map((p) =>
        parseProductRow(p, p.brandId ? _brands[p.brandId] : undefined, options),
      )
      worksheet.addRows(rows)
    }
  }

  if (options.tabs.includes('WRAPPERS')) {
    const worksheet = workbook.addWorksheet('imballi')
    // Columns
    worksheet.columns = generateColumns(productImportWrapperKeys)

    worksheet.getRow(1).protection = {
      locked: true,
    }
    worksheet.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'BCBCBC' },
    }
    worksheet.getRow(1).font = {
      bold: true,
    }
    // Rows
    if (products) {
      const rows: Partial<Row>[] = []
      products.map((p) =>
        (p.wrappers ?? []).map((w, i) =>
          rows.push(parseWrapperRow(productImportWrapperKeys, p, w, i === 0)),
        ),
      )
      worksheet.addRows(rows)
    } else {
      const row: Partial<Row> = parseEmptyWrapperRow(productImportWrapperKeys)
      worksheet.addRows([row])
    }
  }

  if (options.tabs.includes('SITES')) {
    const worksheet = workbook.addWorksheet('ubicazioni')
    worksheet.columns = generateColumns(productImportSiteKeys)
    worksheet.getRow(1).protection = {
      locked: true,
    }
    worksheet.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'BCBCBC' },
    }
    worksheet.getRow(1).font = {
      bold: true,
    }
    // Rows
    if (products) {
      const rows: Partial<Row>[] = []
      products.map((p) =>
        (p.sites ?? []).map((s, i) =>
          rows.push(parseSitesRow(productImportSiteKeys, p, s, i === 0)),
        ),
      )
      worksheet.addRows(rows)
    } else {
      const row: Partial<Row> = parseEmptySitesRow(productImportWrapperKeys)
      worksheet.addRows([row])
    }
  }

  workbook.xlsx
    .writeBuffer()
    .then((buffer: BlobPart) =>
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `prodotti.xlsx`,
      ),
    )
}

export function generateReplenishmebtsFile(
  replenishments?: Replenishment[],
): void {
  // Create workbook
  const workbook = new Workbook()
  const worksheet = workbook.addWorksheet('replenishments')

  // Columns
  worksheet.columns = generateColumns(replenishmentsExcelKeys)

  worksheet.getRow(1).protection = {
    locked: true,
  }
  worksheet.getRow(1).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'BCBCBC' },
  }
  worksheet.getRow(1).font = {
    bold: true,
  }

  if (replenishments?.length) {
    const rows: Partial<Row>[] = []
    replenishments.map((r) =>
      rows.push(parseReplenishmentsRow(replenishmentsExcelKeys, r)),
    )
    worksheet.addRows(rows)
  } else {
    const row: Partial<Row> = parseEmptyReplenishmentRow(
      replenishmentsExcelKeys,
    )
    worksheet.addRows([row])
  }
  workbook.xlsx
    .writeBuffer()
    .then((buffer: BlobPart) =>
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `replenishments.xlsx`,
      ),
    )
}

function generateColumns(keys: string[]): Partial<Column>[] {
  const columns: Partial<Column>[] = []
  keys.map((el) =>
    columns.push({
      header: String(el),
      key: `KEY-${el}`,
      width: columnWidth(el),
    }),
  )
  return columns
}

function columnWidth(key?: string): number | undefined {
  switch (key) {
    case 'azione':
      return 26
    case 'sku':
      return 12
    default:
      return undefined
  }
}

function parseProductRow(
  product: Product,
  brandName: string | undefined,
  options: ProductFileGeneratorOptions,
  catalogCode = CATALOG_DEFAULT_CODE,
  locale = LOCALE_DEFAULT_CODE,
): Partial<Row> {
  const barcode = product.barcodes ? product.barcodes[0].value : product.SKU
  const key =
    options.key === 'id'
      ? product._id
      : options.key === 'sku'
        ? product.SKU
        : barcode
  return {
    [`KEY-${options.key}`]: key,
    ...options.fields.reduce(
      (acc, field) => ({
        ...acc,
        ...parseProductField(product, brandName, field, catalogCode, locale),
      }),
      {},
    ),
  }
}

function parseWrapperRow(
  keys: string[],
  product: Product,
  wrapper: ProductWrapper,
  isFirstRow: boolean,
): Partial<Row> {
  let rows: Partial<Row> = {}
  keys.map(
    (el) =>
      (rows = {
        ...rows,
        [`KEY-${el}`]: parseWrapperField(el, product, wrapper, isFirstRow),
      }),
  )
  return rows
}

function parseEmptyWrapperRow(keys: string[]): Partial<Row> {
  let rows: Partial<Row> = {}
  keys.map(
    (el) =>
      (rows = {
        ...rows,
        [`KEY-${el}`]: parseEmptyWrapperField(el),
      }),
  )
  return rows
}

function parseSitesRow(
  keys: string[],
  product: Product,
  site: ProductSite,
  isFirstRow: boolean,
): Partial<Row> {
  let rows: Partial<Row> = {}
  keys.map(
    (el) =>
      (rows = {
        ...rows,
        [`KEY-${el}`]: parseSiteField(el, product, site, isFirstRow),
      }),
  )
  return rows
}

function parseReplenishmentsRow(
  keys: string[],
  replenishment: Replenishment,
): Partial<Row> {
  let rows: Partial<Row> = {}
  keys.map(
    (el) =>
      (rows = {
        ...rows,
        [`KEY-${el}`]: parseReplenishemntField(el, replenishment),
      }),
  )
  return rows
}

function parseEmptySitesRow(keys: string[]): Partial<Row> {
  let rows: Partial<Row> = {}
  keys.map(
    (el) =>
      (rows = {
        ...rows,
        [`KEY-${el}`]: parseEmptySiteField(el),
      }),
  )
  return rows
}

function parseEmptyReplenishmentRow(keys: string[]): Partial<Row> {
  let rows: Partial<Row> = {}
  keys.map(
    (el) =>
      (rows = {
        ...rows,
        [`KEY-${el}`]: parseEmptyReplenishmentField(el),
      }),
  )
  return rows
}

function parseSiteField(
  key: string,
  product: Product,
  site: ProductSite,
  isFirstRow: boolean,
) {
  let value: any
  switch (key) {
    case 'sku':
      value = `${product.SKU}`
      break
    case 'azione':
      value = isFirstRow ? '[ADD, REPLACE, REMOVE, EMPTY]' : ''
      break
    // The object ProductSite doesn't contain the info on the location path,
    // it would be necessary to get the Location object from the BE starting
    // from each site id for each product, which would be too much time consuming
    // and too demanding in terms of resources. For now, I'll leave the path empty.
    case 'percorso':
      value = ''
      break
  }

  return value
}
function parseReplenishemntField(key: string, replenishment: Replenishment) {
  let value: any
  switch (key) {
    case 'sku':
      value = `${replenishment.product?.SKU ?? ''}`
      break
  }
  return value
}

function parseEmptySiteField(key: string) {
  let value = ''
  switch (key) {
    case 'azione':
      value = '[ADD, REPLACE, REMOVE, EMPTY]'
      break
  }
  return value
}

function parseEmptyReplenishmentField(key: string) {
  let value = ''
  switch (key) {
    case 'action':
      value = '[CREATE, INSERT, UPDATE, UPSERT]'
      break
    case 'source':
      value = '[PATH]'
      break
    case 'target':
      value = '[PATH]'
      break
    case 'priority':
      value = '[LOW, MEDIUM, HIGH]'
      break
  }
  return value
}

function parseWrapperField(
  key: string,
  product: Product,
  wrapper: ProductWrapper,
  isFirstRow: boolean,
) {
  let value: any
  switch (key) {
    case 'sku':
      value = `${product.SKU}`
      break
    case 'nome':
      value = wrapper.label
      break
    case 'azione':
      value = isFirstRow ? '[ADD, REPLACE, REMOVE, EMPTY]' : ''
      break
    case 'barcode':
      value = wrapper.barcode
      break
    case 'autospedente':
      value = wrapper.isSelfShipping ?? ''
      break
    case 'numero_pezzi_figlio':
      value = wrapper.multiplier
      break
    case 'barcode_imballo_figlio':
      value = wrapper.childBarcode ?? ''
      break
    case 'lunghezza':
      value = wrapper.dimensions?.length ?? ''
      break
    case 'altezza':
      value = wrapper.dimensions?.height ?? ''
      break
    case 'larghezza':
      value = wrapper.dimensions?.width ?? ''
      break
    case 'volume':
      value = wrapper.dimensions?.volume ?? ''
      break
    case 'peso':
      value = wrapper.dimensions?.weight ?? ''
      break
  }

  return value
}

function parseEmptyWrapperField(key: string) {
  let value = ''
  switch (key) {
    case 'azione':
      value = '[ADD, REPLACE, REMOVE, EMPTY]'
      break
    case 'autospedente':
      value = '[TRUE/FALSE]'
      break
  }
  return value
}

function parseProductField(
  product: Product,
  brandName: string | undefined,
  field: ProductFileGeneratorField,
  catalogCode: string,
  locale: string,
) {
  const key = field.field.includes('attribute') ? 'attribute' : field.field
  let value = undefined

  if (field.method !== FileUpdateMethod.unset) {
    switch (key) {
      case 'sku':
        value = product.SKU
        break
      case 'attribute':
        value = getProductAttributeByField(product, field, catalogCode, locale)
        break
      case 'barcode':
        value = product.barcodes ? product.barcodes[0].value : undefined
        break
      case 'brand':
        value = brandName
        break
      case 'description':
        value = getCatalogValue(product.description, catalogCode, locale)
        break
      case 'name':
        value = getCatalogValue(product.name, catalogCode, locale)
        break
      case 'notes':
        value = product.notes
        break
      case 'price.discount':
        value =
          product.price?.discount !== undefined
            ? getCatalogPriceValue(product.price.discount, catalogCode, locale)
            : ''
        break
      case 'price.listing':
        value =
          product.price?.listing !== undefined
            ? getCatalogPriceValue(product.price.listing, catalogCode, locale)
            : ''
        break
      case 'price.purchase':
        value =
          product.price?.purchase !== undefined
            ? getCatalogPriceValue(product.price.purchase, catalogCode, locale)
            : ''
        break
      case 'status':
        value = product.status
        break
      case 'tag':
        value = (product.tags || []).join(',')
        break
      default:
        break
    }
  } else {
    value = 'TRUE'
  }

  return { [field.field]: value }
}

function parseColumnField(
  field: ProductFileGeneratorField,
  catalogCode: string,
  locale: string,
): Partial<Column> {
  const key = field.field.includes('attribute') ? 'attribute' : field.field
  let header = `${field.method}@${key}`

  if (field.attribute) {
    header = `${header}:${field.attribute.code}`
  }

  const scope = []
  if (field.valuePerCatalog) {
    scope.push(catalogCode)
  }

  if (field.isLocalizable) {
    scope.push(locale)
  }

  if (scope.length) {
    header = `${header}[${scope.join('|')}]`
  }

  return {
    key: field.field,
    header,
  }
}
