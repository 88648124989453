import { ShippingAddress } from '../../models/address.model'
import { Entity } from '../../models/entity.model'
import { QueryStringParams } from '../../models/query-string.model'
import { StatusHistory } from '../../models/util.model'
import { Location } from '../locations'
import { Coverage } from '../orders/order.model'
import { Product, ProductBarcode, ProductVolume } from '../products'
import { User } from '../users'

export enum PickingListStatus {
  pending = 'PENDING',
  processing = 'PROCESSING',
  picked = 'PICKED',
  closed = 'CLOSED',
}

export enum PickingListMissionStatus {
  pending = 'PENDING',
  processing = 'PROCESSING',
  picked = 'PICKED',
  canceled = 'CANCELED',
  skipped = 'SKIPPED',
  repWaiting = 'WAITING_REPLENISHMENT',
  repCanceled = 'REPLENISHMENT_CANCELED',
}

export enum PickingListOrderStatus {
  processing = 'PROCESSING',
  picked = 'PICKED',
}

export enum PickingListType {
  /**
   * @describe Per ordine
   */
  pack = 'PACK',
  /**
   * @describe Massivo
   */
  sort = 'SORT',
}

export interface PickingList extends Entity {
  pickingNumber?: number
  warehouseId: string
  name: string
  status: PickingListStatus
  statusHistory: StatusHistory[]
  type: PickingListType
  orders: PickingListOrder[]
  totes: PickingListTote[]
  missions: PickingListMission[]
  ordersCount: number
  itemsCount: number
  notes?: string
  isPartial?: boolean
  isUrgent?: boolean
  isBlocked?: boolean
  /**
   * Total products orders volume(m³) and weight(kg)
   */
  estimatedVolume?: number
  estimatedWeight?: number
  picks?: PickingListPics[]
}

export interface PickingListPics {
  date: string
  missionId: string
  productId: string
  qtyPicked: number
  toteId: string
  userId: string
}

export interface PickingListOrder {
  _id: string
  code: string
  carrierId: string
  header: PickingListOrderHeader
  rows: PickingListOrderRow[]
  rowsCount: number
  itemsCount: number
  status?: Coverage
  estimatedVolume?: number
  estimatedWeight?: number
}

export interface PickingListOrderHeader {
  orderNumber: number
  date?: string
  rifOrder?: string
  rifDate?: string
  channel: string
  shippingAddress: ShippingAddress
  notes?: string
  carrierNotes?: string
  errors: string[]
}

export interface PickingListOrderRow {
  productId: string
  status?: Coverage
  orderedQty: number
  leftQty?: number
}

export interface PickingListTote {
  _id: string
  code: string
  path: string
  orderIds: string[]
}

export interface PickingListMission {
  _id: string
  orders: PickingListMissionOrder[]
  location: PickingListMissionLocation
  toteId?: string
  additionalToteIds?: string[]
  product: PickingListMissionProduct
  status: PickingListMissionStatus
  qtyToPick: number
  qtyPicked: number
  partial?: boolean
  lot?: string
  expirationDate?: string
  serial?: string
}

export interface PickingListMissionOrder {
  _id: string
  qtyToPick: number
  qtyPicked: number
}

export interface PickingListMissionProduct {
  _id: string
  SKU: string
  name: string
  barcodes: ProductBarcode[]
  brandId?: string
  tags?: string[]
  volume?: ProductVolume
  grossWeight?: number
}

export interface PickingListMissionLocation {
  _id: string
  code: string
  path: string
}

export interface PickingListMissionData {
  mission: PickingListMission
  nextMission?: PickingListMission
  product: Product
  location: Location
  tote?: Location
  usedTotes?: Location[]
}

export interface PickingListsListingPage {
  data?: PickingList[]
  extData?: PickingListsListingData
  totalCount?: number
}

export interface PickingListsListingKeys {
  userIds?: string[]
}

export interface PickingListsListingData {
  users?: User[]
}

export interface PickingListProductBatchManagement {
  enabled: boolean
  lotRequired?: boolean
  expirationDateRequired?: boolean
  serialRequired?: boolean
}

export interface PickingListTest extends PickingList {
  unusable?: PickingUnusableProduct[]
}

export interface PickingUnusableProduct {
  locationId: string
  orderId: string
  productId: string
}

export type PickingListAction = 'process'

export type PickingListPermission =
  | 'addOrders'
  | 'editHeader'
  | 'editNotes'
  | 'printMissions'
  | 'savePicking'

export type PickingListField =
  | '_id'
  | 'name'
  | 'pickingNumber'
  | 'status'
  | 'type'
  | 'ordersCount'
  | 'itemsCount'
  | 'createdAt'
  | 'createdBy'
  | 'startedBy'
  | 'pickedBy'
  | 'packedBy'
  | 'notes'
  | 'isPartial'
  | 'isUrgent'
  | 'isBlocked'

export type PickingListSearchField =
  | '_id'
  | 'name'
  | 'pickingNumber'
  | 'status'
  | 'type'
  | 'ordersCount'
  | 'itemsCount'
  | 'createdAt'
  | 'notes'
  | 'isPartial'
  | 'isUrgent'
  | 'isBlocked'

export type PickingListSortField =
  | 'pickingNumber'
  | 'createdAt'
  | 'ordersCount'
  | 'itemsCount'
  | 'isUrgent'

export interface PickingListSearchParams
  extends QueryStringParams<PickingListSortField> {
  name?: string
  'name:ne'?: string
  warehouseId?: string
  pickingNumber?: number
  'pickingNumber:ne'?: number
  'pickingNumber:lt'?: number
  'pickingNumber:le'?: number
  'pickingNumber:gt'?: number
  'pickingNumber:ge'?: number
  status?: PickingListStatus | PickingListStatus[]
  type?: PickingListType | PickingListType[]
  ordersCount?: number
  'ordersCount:ne'?: number
  'ordersCount:lt'?: number
  'ordersCount:le'?: number
  'ordersCount:gt'?: number
  'ordersCount:ge'?: number
  itemsCount?: number
  'itemsCount:ne'?: number
  'itemsCount:lt'?: number
  'itemsCount:le'?: number
  'itemsCount:gt'?: number
  'itemsCount:ge'?: number
  createdAt?: string
  'createdAt:ne'?: string
  'createdAt:lt'?: string
  'createdAt:le'?: string
  'createdAt:gt'?: string
  'createdAt:ge'?: string
  notes?: string
  'notes:ne'?: string
  'notes:ex'?: boolean
  isPartial?: boolean
  isUrgent?: boolean
  isBlocked?: boolean
  q?: string
}

export interface PickingListOrderParams {
  limit: number
  channelId?: string
}

export interface PickingListLocationPicked {
  path: string
  qtyPicked: number
}

export type PickingListNotificationCode =
  | 'PICKING_LIST_DELETION'
  | 'PICKING_LIST_PARTIAL'
  | 'PICKING_LIST_SAVED'
  | 'PICKING_LIST_INCOMPLETE'
  | 'PICKING_LIST_DELETED'
  | 'PICKING_LIST_PRINTED'
  | 'PICKING_LIST_MISSIONS_PRINTED'
  | 'PICKING_WAREHOUSE_MISSING'
  | 'PICKING_ORDERS_DUPLICATED'
  | 'PICKING_ORDERS_TOO_MANY'
  | 'PICKING_ORDERS_NOT_AVAILABLE'
  | 'PICKING_ORDER_EMPTY'

export interface PickingListNotificationOptions {
  code: PickingListNotificationCode
  data?: PickingListNotificationData
}

export interface PickingListNotificationData {
  pickingList?: PickingList
  order?: PickingListOrder
}
