import { BulkUpdateMethod } from '../../models/bulk-update.model'
import { Entity } from '../../models/entity.model'
import {
  File,
  FileDownloadType,
  FileUpdateMethod,
  FileUpdateType,
} from '../files/file.model'
import { QueryStringParams } from '../../models/query-string.model'
import { ReasonType } from '../reasons/reason.model'
import { SupplierOrder } from '../supplier-orders/supplier-order.model'
import { Supplier } from '../suppliers/supplier.model'
import { CatalogLocales, Price } from '../../models/util.model'
import { ViewTargetParam } from '../views/view.model'
import { Channel } from '../channels/channel.model'
import { ProductFamily } from '../product-families/product-family.model'
import { TenantCatalogBarcode } from '../tenants/tenant.model'
import { Tag } from '../tags/tag.model'
import { Order } from '../orders/order.model'
import {
  Attribute,
  AttributeOption,
  AttributeField,
} from '../attributes/attribute.model'
import { Category } from '../categories/category.model'
import { GoodsReceive } from '../goods-receives/goods-receive.model'
import { HttpErrorResponse } from '@angular/common/http'
import { Warehouse } from '../warehouses'
import { Location } from '../locations'
import { User } from '../users'
import { Catalog } from '../catalogs'
import { NotificationLevel } from '../../models/notification.model'

export interface Product extends Entity {
  // Keys
  SKU: string
  externalSKUs?: ProductExtSKU[]
  barcodes?: ProductBarcode[]

  // Descriptions
  name: CatalogLocales<string>[]
  shortDescription?: CatalogLocales<string>[]
  description?: CatalogLocales<string>[]
  notes?: string
  productType: ProductType
  status: ProductStatus

  // Kit info
  kitProducts?: ProductKitProd[]

  // Taxonomy
  categories?: ProductCatalogCategory[]
  classifications?: ProductCatalogClassification[]

  // Additional info
  family?: ProductFamilySettings
  parentId?: string
  kitIds?: string
  brandId?: string
  manufacturerId?: string
  suppliers?: ProductSupplier[]
  tags?: string[]
  maxProvisions?: number
  minProvisions?: number
  netWeight?: number
  grossWeight?: number
  logisticsDescription?: string
  abcClass?: string
  webEnabled?: boolean
  volume?: ProductVolume
  isDropShip?: boolean
  attributes?: ProductCatalogAttribute[]
  files?: ProductFile[]
  batchManagement?: ProductBatchManagement
  variantsManagement?: ProductVariantsManagement
  infiniteStocks?: boolean
  sites?: ProductSite[]
  wrappers?: ProductWrapper[]

  // Stock count info
  stockCountId?: string
  countedAt?: Date

  // Prices
  price?: ProductPrice
  taxRate?: number

  // Quantities
  onHandQty: number
  availableQty: number
  incomingQty: number
  receivingQty?: number
  scrappedQty?: number
  packQty?: number
  palletQty?: number

  // Quantities details
  locations: ProductLocation[]
  warehouses: ProductWarehouse[]

  // Sales
  sales?: ProductSale[]

  // Custom info
  customsInfo?: ProductCustomsInfo
}

export interface ProductPageData extends Product {
  brandName?: string
  manufacturerName?: string
  familyName?: string
  historicQuantity?: number
  parent?: Product
  kits?: Product[]
  locationsData?: Location[]
  primaryCategory?: Category
  categoriesData?: Category[]
  channels?: Channel[]
  suppliersData?: Supplier[]
  tagsData?: Tag[]
  attributesData?: Record<string, any>
}

export interface ProductData {
  product: Product
  catalog?: Catalog
  family?: ProductFamily
  files?: File[]
  channels?: Channel[]
  suppliers?: Supplier[]
  kits?: Product[]
  users?: User[]
}

export interface ProductVariantsData {
  product?: Product
  parent?: Product
  children?: Product[]
  kits?: Product[]
}

export interface ProductExtSKU {
  channelId?: string
  value: string
  active: boolean
}

export interface ProductBarcode {
  type: ProductBarcodeType
  value: string
  qty?: number
}

export enum ProductBarcodeType {
  ean13 = 'EAN13',
  ean8 = 'EAN8',
  minsan = 'MINSAN',
  generic = 'GENERIC',
  gtin14 = 'GTIN14',
}

export enum ProductType {
  simple = 'SIMPLE',
  kit = 'KIT',
  package = 'PACKAGE',
  service = 'SERVICE',
  virtual = 'VIRTUAL',
}

export enum ProductStatus {
  enabled = 'ENABLED',
  disabled = 'DISABLED',
}

export interface ProductKitProd {
  _id: string
  SKU: string
  quantity: number
}

export interface ProductCatalogCategory {
  /**
   * Catalog code
   */
  catalogCode: string
  /**
   * Categories IDs assigned to product
   */
  ids: string[]
  /**
   * Main category ID assigned to product
   */
  primaryId?: string
}

export interface ProductCatalogClassification {
  catalogCode: string
  ids: string[]
}

export interface ProductFamilySettings {
  code?: string
  complete: CatalogLocales<number>[]
  fields: ProductFamilySettingsField[]
  attributes: ProductFamilySettingsAttribute[]
}

export interface ProductFamilySettingsField {
  field: ProductCompletableField
  requiredIn: string[]
}

export interface ProductFamilySettingsAttribute {
  code: string
  requiredIn: string[]
}

export interface ProductCatalogAttribute extends CatalogLocales<any> {
  attributeCode: string
}

export interface ProductVolume {
  total?: number
  w?: number
  h?: number
  l?: number
}

export interface ProductSupplier {
  supplierId?: string
  SKU?: string
  price: ProductSupplierPrice
  modifiedAt?: Date
  moq: number
  lotQty: number
  currency: string
}

export interface ProductSupplierPrice {
  purchase: number
  discounts: number[]
  net: number
  promotion?: ProductDiscount
}

export interface ProductFile {
  _id: string
  path: string
}

export interface ProductBatchManagement {
  enabled: boolean
  lotRequired?: boolean
  expirationDateRequired?: boolean
  serialRequired?: boolean
}

export interface ProductVariantsManagement {
  attributeIds: string[]
  childrenIds?: string[]
}

export interface ProductPrice {
  purchase?: CatalogLocales<Price>[]
  listing?: CatalogLocales<Price>[]
  discount?: CatalogLocales<ProductDiscount>[]
}

export interface ProductDiscount extends Price {
  name?: string
  percentage?: number
  startDate?: Date
  endDate?: Date
  minQty?: number
}

export interface ProductLocation {
  _id: string
  warehouseId: string
  /**
   * Serviceable physical quantity contained inside this location.
   */
  onHandQty: number
  /**
   * Reserved (locked) physical quantity (against onHandQty).
   */
  reservedQty: number
  /**
   * Picked (moving) physical quantity.
   */
  pickedQty: number
  /**
   * Discarded (or defective) physical quantity.
   */
  scrappedQty?: number
  batches?: ProductBatch[]
}

export interface ProductBatch {
  onHandQty: number
  reservedQty?: number
  pickedQty?: number
  scrappedQty?: number
  lot?: string
  expirationDate?: string
  serial?: string
}

export interface ProductWarehouse {
  _id: string
  onHandQty: number
  availableQty: number
  incomingQty: number
  scrappedQty?: number
}

export interface ProductSale {
  warehouseId?: string
  soldFrom: Date
  soldTo: Date
  qty: number
  price?: number
}

export interface ProductSalesByYear {
  [year: string]: ProductSale[]
}

export interface ProductCustomsInfo {
  description?: string
  hsCode?: string
  originCountry?: string
}

export interface ProductSite {
  /**
   * Site's identifier.
   */
  _id: string
  /**
   * Location identifier.
   * Can be `PICK` or `STOCK` (leaf only).
   */
  locationId: string
  /**
   * Location's Area identifier.
   */
  areaId: string
  /**
   * Location's Warehouse identifier.
   */
  warehouseId: string
}

export interface ProductWrapper {
  label: string
  barcode: string
  /**
   * If true this wrapper is added to a shipment packages when its barcode is used during the packing.
   */
  isSelfShipping?: boolean
  dimensions?: ProductWrapperDimensions
  /**
   * Number of children in this wrapper.
   *
   * Total product qty in this wrapper can be found in the product root `barcodes` property.
   */
  multiplier: number
  /**
   * Which wrapper is contained in this wrapper.
   */
  childBarcode?: string
}

export interface ProductWrapperDimensions {
  /**
   * Centimeters.
   */
  height?: number
  /**
   * Centimeters.
   */
  length?: number
  /**
   * Centimeters.
   */
  width?: number
  /**
   * Cubic meters.
   */
  volume?: number
  /**
   * Kilograms.
   */
  weight?: number
}

export interface ProductSelection {
  product: Product
  qty?: number
}

export interface ProductProvisioningSettings {
  useIncomingQty?: boolean
  useReceivingQty?: boolean
  useMaxProvisions?: boolean
  useMinProvisions?: boolean
  includeDropShipping?: boolean
}

export interface ProductScope {
  catalogCode: string
  locale: string
  warehouseId?: string
  supplierId?: string
  categoriesRootId?: string
}

export interface ProductBatchSignature {
  lot?: string
  expirationDate?: string
  serial?: string
}

export interface ProductNotification {
  kind: ProductNotificationCode
  level?: NotificationLevel
  data?: { product?: Product; barcodes?: TenantCatalogBarcode[] }
}

export type ProductNotificationCode =
  | 'PRODUCT_INCOMPLETE'
  | 'PRODUCT_SAVED'
  | 'MISSING_BARCODES'
  | 'LABEL_PRINTED'
  | 'MISSING_BARCODES'
  | 'MISSING_MANDATORY_FIELDS'
  | 'MISSING_KIT_PRODUCTS'
  | 'MISSING_VARIANT_ATTRIBUTE'
  | 'BATCH_MANAGEMENT_NOT_VALID'

export enum ProductManagementKind {
  lot = 'lot',
  expirationDate = 'expirationDate',
  both = 'both',
}

export enum ProductFieldGroup {
  attributes = 'ATTRIBUTES',
  categories = 'CATEGORIES',
  primaryCategory = 'CATEGORY',
  classifications = 'CLASSIFICATIONS',
  general = 'GENERAL',
  prices = 'PRICES',
  logistic = 'LOGISTIC',
  sites = 'SITES',
  tags = 'TAGS',
}

export enum ProductBulkUpdateField {
  attribute = 'ATTRIBUTE',
  batchManagement = 'BATCH_MANAGEMENT',
  brand = 'BRAND',
  categories = 'CATEGORIES',
  primaryCategory = 'PRIMARY_CATEGORY',
  dropship = 'DROPSHIP',
  grossWeight = 'GROSS_WEIGHT',
  infiniteStocks = 'INFINITE_STOCKS',
  manufacturer = 'MANUFACTURER',
  maxProvisions = 'MAX_PROVISIONS',
  minProvisions = 'MIN_PROVISIONS',
  abcClass = 'ABC_CLASS',
  netWeight = 'NET_WEIGHT',
  packQty = 'PACK_QUANTITY',
  palletQty = 'PALLET_QUANTITY',
  parent = 'PARENT',
  priceDiscount = 'PRICE_DISCOUNT',
  priceListing = 'PRICE_LISTING',
  pricePurchase = 'PRICE_PURCHASE',
  productType = 'PRODUCT_TYPE',
  sites = 'SITES',
  status = 'STATUS',
  supplier = 'SUPPLIER',
  tags = 'TAGS',
  taxRate = 'TAX_RATE',
  volume = 'VOLUME',
}

export enum ProductTab {
  general = 'general',
  variants = 'variants',
  logistic = 'logistic',
  photos = 'photos',
  categories = 'categories',
  classifications = 'classifications',
  prices = 'prices',
  sales = 'sales',
  attributes = 'attributes',
  kit = 'kit',
}

export enum ProductSkuType {
  numeric = 'numeric',
  coded = 'coded',
}

export enum ProductImportKey {
  id = 'id',
  sku = 'sku',
  barcode = 'barcode',
}

export const productImportWrapperKeys = [
  'sku',
  'azione',
  'nome',
  'barcode',
  'autospedente',
  'numero_pezzi_figlio',
  'barcode_imballo_figlio',
  'altezza',
  'lunghezza',
  'larghezza',
  'volume',
  'peso',
]

export const productImportSiteKeys = ['sku', 'azione', 'percorso']

export type ProductFileTabs = 'PRODUCTS' | 'WRAPPERS' | 'SITES'

export interface ProductMovement {
  locationId?: string
  warehouseId?: string
  pickingListId?: string
  quantity: number
  reasonId: string
  action?: ReasonType
  lot?: string
  expirationDate?: string
  serial?: string
  notes?: string
}

export interface ProductBulkUpdate {
  catalogCode?: string
  filter: ProductSearchParams
  count: number
  locale?: string
  mode?: BulkUpdateMethod
  subject?: ProductBulkUpdateField
  value?: any
  attributeCode?: string
}

export interface ProductConfiguration {
  product: Partial<Product>
  attributes: Attribute[]
  options: AttributeOption[][]
  sku: {
    type?: ProductSkuType
    dividerChar?: string
    tailLength?: number
  }
  name: {
    dividerChar?: string
  }
  variants: {
    sku: string
    name: string
    options: string[]
  }[]
}

export interface ProductBarcodeValidation {
  valid: boolean
  missings?: TenantCatalogBarcode[]
}

export interface ProductParam extends ViewTargetParam {}

export interface ProductFileGeneratorOptions {
  type: FileUpdateType
  key?: ProductImportKey
  fields: ProductFileGeneratorField[]
  download: FileDownloadType
  tabs: ProductFileTabs[]
}
export interface ProductFileGeneratorField extends AttributeField {
  method: FileUpdateMethod
}

export interface ProductBulkUpdateResponse {
  succeeded: string[]
  failed: string[]
  totalCount: number
}

export interface ProductOrder extends Order {
  orderedQty: number
  consumptionQty: number
}

export interface ProductSupplierOrder extends SupplierOrder {
  orderedQty: number
  receivedQty: number
}

export interface ProductGoodsReceive extends GoodsReceive {
  orderedQty: number
  receivedQty: number
  invoicedQty: number
}

export interface ProductExportStockData {
  warehouseId: string | undefined
  from: Date
  to: Date
  email: string
}

export interface ProductHistoricStockData {
  warehouseId: string | undefined
  date: string
}

export interface ProductSearchParams
  extends QueryStringParams<ProductSortField> {
  createdAt?: string
  'createdAt:ne'?: string
  'createdAt:lt'?: string
  'createdAt:le'?: string
  'createdAt:gt'?: string
  'createdAt:ge'?: string
  updatedAt?: string
  'updatedAt:ne'?: string
  'updatedAt:lt'?: string
  'updatedAt:le'?: string
  'updatedAt:gt'?: string
  'updatedAt:ge'?: string
  SKU?: string | string[]
  'SKU:ne'?: string
  barcodes?: string | string[]
  'barcodes:ne'?: string
  'channels._id'?: string | string[]
  'channels._id:ex'?: boolean
  'channels.SKU'?: string | string[]
  'channels.active'?: boolean
  name?: string
  'name:ne'?: string
  shortDescription?: string
  'shortDescription:ne'?: string
  description?: string
  'description:ne'?: string
  notes?: string
  'notes:ne'?: string
  productType?: ProductType | ProductType[]
  'productType:ex'?: boolean
  status?: ProductStatus | ProductStatus[]
  'status:ex'?: boolean
  categories?: string | string[]
  'categories:ex'?: boolean
  classifications?: string | string[]
  'classifications:ex'?: boolean
  'family.code'?: string | string[]
  'family.code:ex'?: boolean
  'family.complete'?: number
  'family.complete:ne'?: number
  'family.complete:lt'?: number
  'family.complete:le'?: number
  'family.complete:gt'?: number
  'family.complete:ge'?: number
  parentId?: string | string[]
  'parentId:ex'?: boolean
  kitIds?: string | string[]
  'kitIds:ex'?: boolean
  brandId?: string | string[]
  'brandId:ex'?: boolean
  manufacturerId?: string | string[]
  'manufacturerId:ex'?: boolean
  'suppliers._id'?: string | string[]
  'suppliers._id:ex'?: boolean
  'suppliers.SKU'?: string | string[]
  tags?: string | string[]
  'tags:ex'?: boolean
  maxProvisions?: number
  'maxProvisions:ne'?: number
  'maxProvisions:lt'?: number
  'maxProvisions:le'?: number
  'maxProvisions:gt'?: number
  'maxProvisions:ge'?: number
  minProvisions?: number
  'minProvisions:ne'?: number
  'minProvisions:lt'?: number
  'minProvisions:le'?: number
  'minProvisions:gt'?: number
  'minProvisions:ge'?: number
  netWeight?: number
  'netWeight:ne'?: number
  'netWeight:lt'?: number
  'netWeight:le'?: number
  'netWeight:gt'?: number
  'netWeight:ge'?: number
  grossWeight?: number
  'grossWeight:ne'?: number
  'grossWeight:lt'?: number
  'grossWeight:le'?: number
  'grossWeight:gt'?: number
  'grossWeight:ge'?: number
  logisticsDescription?: string
  'logisticsDescription:ne'?: string
  abcClass?: string
  'abcClass:ct'?: string
  'abcClass:ne'?: string
  'abcClass:ex'?: boolean
  webEnabled?: boolean
  'volume.w'?: number
  'volume.w:ne'?: number
  'volume.w:lt'?: number
  'volume.w:le'?: number
  'volume.w:gt'?: number
  'volume.w:ge'?: number
  'volume.h'?: number
  'volume.h:ne'?: number
  'volume.h:lt'?: number
  'volume.h:le'?: number
  'volume.h:gt'?: number
  'volume.h:ge'?: number
  'volume.l'?: number
  'volume.l:ne'?: number
  'volume.l:lt'?: number
  'volume.l:le'?: number
  'volume.l:gt'?: number
  'volume.l:ge'?: number
  'volume.total'?: number
  'volume.total:ne'?: number
  'volume.total:lt'?: number
  'volume.total:le'?: number
  'volume.total:gt'?: number
  'volume.total:ge'?: number
  isDropShip?: boolean
  'files:ex'?: boolean
  batchManagement?: boolean
  'batchManagement.lotRequired'?: boolean
  'batchManagement.expirationDateRequired'?: boolean
  'batchManagement.serialRequired'?: boolean
  infiniteStocks?: boolean
  stockCountId?: string | string[]
  'stockCountId:ex'?: boolean
  countedAt?: string
  'countedAt:ne'?: string
  'countedAt:lt'?: string
  'countedAt:le'?: string
  'countedAt:gt'?: string
  'countedAt:ge'?: string
  'price.purchase'?: number
  'price.purchase:ne'?: number
  'price.purchase:lt'?: number
  'price.purchase:le'?: number
  'price.purchase:gt'?: number
  'price.purchase:ge'?: number
  'price.listing'?: number
  'price.listing:ne'?: number
  'price.listing:lt'?: number
  'price.listing:le'?: number
  'price.listing:gt'?: number
  'price.listing:ge'?: number
  'price.discount'?: number
  'price.discount:ne'?: number
  'price.discount:lt'?: number
  'price.discount:le'?: number
  'price.discount:gt'?: number
  'price.discount:ge'?: number
  taxRate?: number
  'taxRate:ne'?: number
  'taxRate:lt'?: number
  'taxRate:le'?: number
  'taxRate:gt'?: number
  'taxRate:ge'?: number
  onHandQty?: number
  'onHandQty:ne'?: number
  'onHandQty:lt'?: number
  'onHandQty:le'?: number
  'onHandQty:gt'?: number
  'onHandQty:ge'?: number
  availableQty?: number
  'availableQty:ne'?: number
  'availableQty:lt'?: number
  'availableQty:le'?: number
  'availableQty:gt'?: number
  'availableQty:ge'?: number
  incomingQty?: number
  'incomingQty:ne'?: number
  'incomingQty:lt'?: number
  'incomingQty:le'?: number
  'incomingQty:gt'?: number
  'incomingQty:ge'?: number
  receivingQty?: number
  'receivingQty:ne'?: number
  'receivingQty:lt'?: number
  'receivingQty:le'?: number
  'receivingQty:gt'?: number
  'receivingQty:ge'?: number
  scrappedQty?: number
  'scrappedQty:lt'?: number
  'scrappedQty:le'?: number
  'scrappedQty:gt'?: number
  'scrappedQty:ge'?: number
  packQty?: number
  'packQty:ne'?: number
  'packQty:lt'?: number
  'packQty:le'?: number
  'packQty:gt'?: number
  'packQty:ge'?: number
  palletQty?: number
  'palletQty:ne'?: number
  'palletQty:lt'?: number
  'palletQty:le'?: number
  'palletQty:gt'?: number
  'palletQty:ge'?: number
  'customsInfo.description'?: string
  'customsInfo.description:ne'?: string
  'customsInfo.hsCode'?: string
  'customsInfo.hsCode:ne'?: string
  'customsInfo.originCountry'?: string
  'customsInfo.originCountry:ne'?: string
  warehouseId?: string
  locale?: string
  catalogCode?: string
  q?: string
  code?: string

  /**
   * Search by attribute value.
   */
  [attributeCode: string]: any
}

export type ProductViewType =
  | 'LISTING_MAIN'
  | 'PRODUCTS_LISTING_MAIN'
  | 'PRODUCTS_SELECTION_ORDER'
  | 'PRODUCTS_SELECTION_GOODS_RECEIVE'
  | 'PRODUCTS_SELECTION_GOODS_RETURN'
  | 'PRODUCTS_SELECTION_INVOICE'
  | 'PRODUCTS_SELECTION_VARIANTS'
  | 'PRODUCTS_SELECTION_KITS'
  | 'PRODUCTS_SELECTION_STOCK_COUNT'
  | 'PRODUCTS_SELECTION_SUPPLIER_ORDER'

export type ProductField =
  | '_id'
  | 'createdAt'
  | 'updatedAt'
  | 'SKU'
  | 'channels.active'
  | 'channels._id'
  | 'channels.SKU'
  | 'externalSKUs'
  | 'externalSKUs.channelId'
  | 'barcodes'
  | 'name'
  | 'shortDescription'
  | 'description'
  | 'notes'
  | 'productType'
  | 'status'
  | 'categories'
  | 'categories.primaryId'
  | 'classifications'
  | 'family.code'
  | 'family.complete'
  | 'parentId'
  | 'kitIds'
  | 'brandId'
  | 'manufacturerId'
  | 'suppliers._id'
  | 'suppliers.supplierId'
  | 'suppliers.SKU'
  | 'tags'
  | 'maxProvisions'
  | 'minProvisions'
  | 'netWeight'
  | 'grossWeight'
  | 'logisticsDescription'
  | 'abcClass'
  | 'webEnabled'
  | 'volume.w'
  | 'volume.h'
  | 'volume.l'
  | 'volume.total'
  | 'isDropShip'
  | 'attributes'
  | 'files'
  | 'batchManagement'
  | 'batchManagement.enabled'
  | 'batchManagement.lotRequired'
  | 'batchManagement.expirationDateRequired'
  | 'batchManagement.serialRequired'
  | 'infiniteStocks'
  | 'stockCountId'
  | 'countedAt'
  | 'price.purchase'
  | 'price.listing'
  | 'price.discount'
  | 'price.discount.name'
  | 'price.discount.startDate'
  | 'price.discount.endDate'
  | 'taxRate'
  | 'onHandQty'
  | 'availableQty'
  | 'incomingQty'
  | 'receivingQty'
  | 'scrappedQty'
  | 'packQty'
  | 'palletQty'
  | 'customsInfo.description'
  | 'customsInfo.hsCode'
  | 'customsInfo.originCountry'
  | 'sites'

export type ProductSortField =
  | 'createdAt'
  | 'updatedAt'
  | 'SKU'
  | 'name'
  | 'maxProvisions'
  | 'minProvisions'
  | 'netWeight'
  | 'grossWeight'
  | 'abcClass'
  | 'volume.total'
  | 'price.purchase'
  | 'price.listing'
  | 'price.discount'
  | 'onHandQty'
  | 'availableQty'
  | 'incomingQty'
  | 'receivingQty'

export type ProductCompletableField =
  | 'SKU'
  | 'externalSKUs'
  | 'name'
  | 'description'
  | 'shortDescription'
  | 'taxRate'
  | 'price.purchase'
  | 'price.listing'
  | 'price.discount'
  | 'volume.w'
  | 'volume.h'
  | 'volume.l'
  | 'volume.total'
  | 'netWeight'
  | 'grossWeight'
  | 'packQty'
  | 'brandId'
  | 'files'
  | 'categories'

export type ProductMappingField =
  | 'SKU'
  | 'name'
  | 'description'
  | 'shortDescription'
  | 'taxRate'
  | 'volume.w'
  | 'volume.h'
  | 'volume.l'
  | 'volume.total'
  | 'netWeight'
  | 'grossWeight'
  | 'maxProvisions'
  | 'minProvisions'
  | 'logisticsDescription'
  | 'infiniteStocks'
  | 'packQty'
  | 'onHandQty'

export type ProductImportField =
  | 'abcClass'
  | 'attribute'
  | 'barcode'
  | 'brand'
  | 'customsDescription'
  | 'customsHsCode'
  | 'customsOriginCountry'
  | 'description'
  | 'dropship'
  | 'grossWeight'
  | 'height'
  | 'infiniteStock'
  | 'length'
  | 'logisticsDescription'
  | 'maxProvisions'
  | 'minProvisions'
  | 'name'
  | 'netWeight'
  | 'notes'
  | 'packageQuantity'
  | 'price.discount'
  | 'price.listing'
  | 'price.purchase'
  | 'sku'
  | 'status'
  | 'tag'
  | 'taxRate'
  | 'volume'
  | 'width'

/**
 * USE CASES
 */

export interface ProductsListingPage {
  data?: ProductPageData[]
  totalCount?: number
}

export interface ProductsListingSearchData {
  params: ProductSearchParams
  fields: ProductField[]
  scope?: ProductScope
  attributes?: Attribute[]
  historicData?: ProductHistoricStockData
}

export interface ProductsListingCategories {
  rootId?: string
  currentId?: string
  uncategorized?: boolean
}

export interface ProductsListingState extends ProductsListingPage {
  searchString?: string
  selected?: Product[]
  fields?: ProductField[]
  filters?: ProductSearchParams
  searchKeys?: ProductSearchParams
  customFilters?: ProductSearchParams
  categories?: ProductsListingCategories
  historic?: ProductHistoricStockData
  scope?: ProductScope
  attributes?: Attribute[]
  error?: HttpErrorResponse
  toReload: boolean
  isLoading: boolean
  isInitialized: boolean
}

export interface ProductIncomings {
  warehouses?: ProductWarehouse[]
  suppliers?: Supplier[]
  supplierOrders?: ProductSupplierOrder[]
  goodsReceives?: ProductGoodsReceive[]
}

export interface ProductOutgoings {
  orders?: ProductOrder[]
}

export interface ProductPositions {
  productLocations?: ProductLocation[]
  warehouses?: Warehouse[]
  locations?: Location[]
}

export interface ProductNotificationData {
  product: Product
  notification?: ProductNotification
  toClose?: boolean
}

export interface ProductFilesData {
  product: Product
  files?: File[]
}
