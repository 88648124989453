import { BulkUpdateMethod } from '../../models/bulk-update.model'
import { FileUpdateMethod } from '../files/file.model'
import {
  FilterCollection,
  FilterOperator,
  FilterType,
} from '../../models/filter.model'
import { PermissionsScope } from '../policies/policy.model'
import {
  Product,
  ProductBarcode,
  ProductBarcodeType,
  ProductBulkUpdate,
  ProductBulkUpdateField,
  ProductConfiguration,
  ProductExportStockData,
  ProductFamilySettings,
  ProductField,
  ProductFieldGroup,
  ProductHistoricStockData,
  ProductImportKey,
  ProductMovement,
  ProductProvisioningSettings,
  ProductScope,
  ProductSkuType,
  ProductStatus,
  ProductSupplier,
  ProductTab,
  ProductType,
  ProductViewType,
} from './product.model'
import { SelectionData } from '../../models/util.model'
import { ViewTargetParam } from '../views/view.model'
import { ReasonType } from '../reasons/reason.model'
import {
  CATALOG_DEFAULT_EMPTY_STRING_VALUE,
  CATALOG_DEFAULT_VALUE,
} from '../catalogs/catalog.const'
import { Field } from '../../models/field.model'

// TODO: This behaviour should be managed in a better way => parsing function
export const PRODUCT_SCOPE: PermissionsScope = 'products'
export const PRODUCTS_PATH_KEY = 'products'
export const PRODUCT_PATH_KEY = 'product'
export const PRODUCT_PATH_LOCATIONS_KEY = 'product-locations'
export const PRODUCT_PATH_SKU = 'SKU'
export const PRODUCT_PATH_ORDER = 'orderId'
export const PRODUCT_PATH_SUPPLIER_ORDER = 'supplierOrderId'
export const PRODUCT_PATH_GOODS_RECEIVE = 'goodsReceiveId'
export const PRODUCT_PATH_PICKING_LIST = 'pickingListId'
export const PRODUCT_PATH_MODIFY = 'modify'

export const PRODUCT_HEAVY_WEIGHT = 10
export const PRODUCT_BULKY_VOLUME = 0.096

// Configurations

export const PRODUCT_TYPES = [
  {
    code: ProductType.simple,
    name: 'Semplice',
    icon: 'fad fa-book',
    isQuantifiable: true,
    isAvailable: true,
    isAcceptable: true,
  },
  {
    code: ProductType.virtual,
    name: 'A varianti',
    icon: 'fad fa-tshirt',
    isQuantifiable: false,
    isAvailable: false,
    isAcceptable: false,
  },
  {
    code: ProductType.service,
    name: 'Servizio',
    icon: 'fad fa-briefcase',
    isQuantifiable: false,
    isAvailable: false,
    isAcceptable: false,
  },
  {
    code: ProductType.kit,
    name: 'Kit',
    icon: 'fad fa-burger-soda',
    isQuantifiable: false,
    isAvailable: false,
    isAcceptable: false,
  },
  {
    code: ProductType.package,
    name: 'Imballo',
    icon: 'fad fa-box-open',
    isQuantifiable: true,
    isAvailable: false,
    isAcceptable: true,
  },
]

export const BARCODE_TYPES = [
  {
    code: ProductBarcodeType.ean13,
    name: 'EAN13',
  },
  {
    code: ProductBarcodeType.ean8,
    name: 'EAN8',
  },
  {
    code: ProductBarcodeType.minsan,
    name: 'Minsan',
  },
  {
    code: ProductBarcodeType.generic,
    name: 'Generico',
  },
  {
    code: ProductBarcodeType.gtin14,
    name: 'GTIN14',
  },
]

export const PRODUCT_FIELD_GROUPS: SelectionData<ProductFieldGroup>[] = [
  {
    value: ProductFieldGroup.attributes,
    label: 'Attributi',
    icon: 'fad fa-bring-front',
  },
  {
    value: ProductFieldGroup.categories,
    label: 'Categorie',
    icon: 'fad fa-folder-tree',
  },
  {
    value: ProductFieldGroup.primaryCategory,
    label: 'Categoria principale',
    icon: 'fad fa-folder-plus',
  },
  {
    value: ProductFieldGroup.tags,
    label: 'Tag',
    icon: 'fad fa-hashtag',
  },
  {
    value: ProductFieldGroup.general,
    label: 'Informazioni generali',
    icon: 'fad fa-info-circle',
  },
  {
    value: ProductFieldGroup.logistic,
    label: 'Informazioni logistiche',
    icon: 'fad fa-dolly',
  },
  {
    value: ProductFieldGroup.prices,
    label: 'Prezzi',
    icon: 'fad fa-tags',
  },
  {
    value: ProductFieldGroup.sites,
    label: 'Ubicazioni consigliate',
    icon: 'fad fa-shelves',
  },
]

export const PRODUCT_STATUSES = [
  {
    code: ProductStatus.enabled,
    name: 'Abilitato',
  },
  {
    code: ProductStatus.disabled,
    name: 'Disabilitato',
  },
]

export const PRODUCT_FIELDS: Field<ProductField, ProductViewType>[] = [
  {
    label: 'Data creazione',
    field: 'createdAt',
    filterOperators: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.between,
      FilterOperator.beforeThan,
      FilterOperator.beforeOrEqualThan,
      FilterOperator.afterThan,
      FilterOperator.afterOrEqualThan,
    ],
    filterType: FilterType.date,
  },
  {
    label: 'Data aggiornamento',
    field: 'updatedAt',
    filterOperators: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.between,
      FilterOperator.beforeThan,
      FilterOperator.beforeOrEqualThan,
      FilterOperator.afterThan,
      FilterOperator.afterOrEqualThan,
    ],
    filterType: FilterType.date,
  },
  {
    label: 'Data inventario',
    field: 'countedAt',
    filterOperators: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.between,
      FilterOperator.beforeThan,
      FilterOperator.beforeOrEqualThan,
      FilterOperator.afterThan,
      FilterOperator.afterOrEqualThan,
    ],
    filterType: FilterType.date,
  },
  {
    label: 'SKU',
    field: 'SKU',
    defaultViews: ['LISTING_MAIN'],
    mappable: true,
    completable: true,
    fileEditable: true,
    fileField: 'sku',
    fileMethods: [FileUpdateMethod.set],
    filterOperators: [FilterOperator.equal, FilterOperator.notEqual],
    filterType: FilterType.text,
  },
  {
    label: 'SKU alternativi',
    field: 'externalSKUs',
    completable: true,
    filterOperators: [FilterOperator.equal],
    filterType: FilterType.text,
  },
  {
    label: 'Nome',
    field: 'name',
    defaultViews: ['LISTING_MAIN'],
    mappable: true,
    completable: true,
    fileEditable: true,
    fileField: 'name',
    fileMethods: [FileUpdateMethod.set],
    valuePerCatalog: true,
    isLocalizable: true,
    filterOperators: [FilterOperator.equal, FilterOperator.notEqual],
    filterType: FilterType.text,
  },
  {
    label: 'Stato',
    field: 'status',
    defaultViews: ['LISTING_MAIN'],
    bulkEditable: true,
    fileEditable: true,
    fileField: 'status',
    fileMethods: [FileUpdateMethod.set, FileUpdateMethod.unset],
    bulkGroup: ProductFieldGroup.general,
    bulkField: ProductBulkUpdateField.status,
    bulkMethods: [BulkUpdateMethod.set],
    filterOperators: [FilterOperator.equal],
    filterType: FilterType.simpleSelect,
    filterOptions: [
      {
        value: ProductStatus.enabled,
        label: 'Enabled',
      },
      {
        value: ProductStatus.disabled,
        label: 'Disabled',
      },
    ],
  },
  {
    label: 'Tipologia prodotto',
    field: 'productType',
    defaultViews: ['LISTING_MAIN'],
    bulkEditable: false,
    fileEditable: true,
    fileField: 'productType',
    fileMethods: [FileUpdateMethod.set],
    bulkGroup: ProductFieldGroup.general,
    bulkField: ProductBulkUpdateField.productType,
    bulkMethods: [BulkUpdateMethod.set],
    filterOperators: [FilterOperator.equal],
    filterType: FilterType.simpleSelect,
    filterOptions: [
      {
        value: ProductType.simple,
        label: 'Semplice',
      },
      {
        value: ProductType.package,
        label: 'Imballo',
      },
      {
        value: ProductType.service,
        label: 'Servizio',
      },
      {
        value: ProductType.virtual,
        label: 'Virtuale',
      },
    ],
  },
  {
    label: 'Barcode',
    field: 'barcodes',
    defaultViews: ['LISTING_MAIN'],
    fileEditable: true,
    fileField: 'barcode',
    fileMethods: [
      FileUpdateMethod.set,
      FileUpdateMethod.unset,
      FileUpdateMethod.push,
      FileUpdateMethod.pull,
    ],
    filterOperators: [FilterOperator.equal, FilterOperator.notEqual],
    filterType: FilterType.text,
  },
  {
    label: 'Brand',
    field: 'brandId',
    defaultViews: ['LISTING_MAIN'],
    completable: true,
    fileEditable: true,
    fileField: 'brand',
    fileMethods: [FileUpdateMethod.set, FileUpdateMethod.unset],
    bulkEditable: true,
    bulkGroup: ProductFieldGroup.general,
    bulkField: ProductBulkUpdateField.brand,
    bulkMethods: [BulkUpdateMethod.set, BulkUpdateMethod.unset],
    filterOperators: [FilterOperator.equal, FilterOperator.exists],
    filterType: FilterType.simpleSelect,
    filterCollection: FilterCollection.brands,
  },
  {
    label: 'Produttore',
    field: 'manufacturerId',
    bulkEditable: true,
    bulkGroup: ProductFieldGroup.general,
    bulkField: ProductBulkUpdateField.manufacturer,
    bulkMethods: [BulkUpdateMethod.set, BulkUpdateMethod.unset],
    filterOperators: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.exists,
    ],
    filterType: FilterType.simpleSelect,
    filterCollection: FilterCollection.manufacturers,
  },
  {
    label: 'Prodotto padre',
    field: 'parentId',
    bulkEditable: true,
    bulkGroup: ProductFieldGroup.general,
    bulkField: ProductBulkUpdateField.parent,
    bulkMethods: [BulkUpdateMethod.set, BulkUpdateMethod.unset],
    bulkOnlySelection: true,
    completable: false,
    filterOperators: [FilterOperator.equal, FilterOperator.exists],
    filterType: FilterType.simpleSelect,
    filterCollection: FilterCollection.products,
  },
  {
    label: 'Prodotti kit',
    field: 'kitIds',
    filterOperators: [FilterOperator.equal, FilterOperator.exists],
    filterType: FilterType.simpleSelect,
    filterCollection: FilterCollection.products,
  },
  {
    label: 'Categorie',
    field: 'categories',
    completable: true,
    bulkEditable: true,
    bulkGroup: ProductFieldGroup.categories,
    bulkField: ProductBulkUpdateField.categories,
    bulkMethods: [
      BulkUpdateMethod.set,
      BulkUpdateMethod.unset,
      BulkUpdateMethod.push,
      BulkUpdateMethod.pull,
    ],
    filterOperators: [FilterOperator.equal, FilterOperator.exists],
    filterType: FilterType.simpleSelect,
    filterCollection: FilterCollection.categories,
  },
  {
    label: 'Categoria principale',
    field: 'categories.primaryId',
    bulkEditable: true,
    bulkGroup: ProductFieldGroup.primaryCategory,
    bulkField: ProductBulkUpdateField.primaryCategory,
    bulkMethods: [BulkUpdateMethod.set, BulkUpdateMethod.unset],
  },
  {
    label: 'Descrizione',
    field: 'description',
    completable: true,
    mappable: true,
    fileEditable: true,
    fileField: 'description',
    fileMethods: [FileUpdateMethod.set, FileUpdateMethod.unset],
    valuePerCatalog: true,
    isLocalizable: true,
    filterOperators: [FilterOperator.equal, FilterOperator.notEqual],
    filterType: FilterType.text,
  },
  {
    label: 'Descrizione breve',
    field: 'shortDescription',
    completable: true,
    mappable: true,
    filterOperators: [FilterOperator.equal, FilterOperator.notEqual],
    filterType: FilterType.text,
  },
  {
    label: 'Canali di vendita',
    field: 'externalSKUs.channelId',
    filterOperators: [FilterOperator.equal, FilterOperator.exists],
    filterType: FilterType.simpleSelect,
    filterCollection: FilterCollection.channels,
  },
  {
    label: 'Classificazioni',
    field: 'classifications',
    filterOperators: [FilterOperator.equal, FilterOperator.exists],
    filterType: FilterType.simpleSelect,
    filterCollection: FilterCollection.channels,
  },
  {
    label: 'Fornitori',
    field: 'suppliers._id',
    bulkEditable: true,
    bulkGroup: ProductFieldGroup.general,
    bulkField: ProductBulkUpdateField.supplier,
    bulkMethods: [
      BulkUpdateMethod.set,
      BulkUpdateMethod.unset,
      BulkUpdateMethod.pull,
      BulkUpdateMethod.push,
    ],
    filterOperators: [FilterOperator.equal, FilterOperator.exists],
    filterType: FilterType.simpleSelect,
    filterCollection: FilterCollection.suppliers,
  },
  {
    label: 'SKU Fornitori',
    field: 'suppliers.SKU',
    filterOperators: [FilterOperator.equal],
    filterType: FilterType.text,
  },
  {
    label: 'Tag',
    field: 'tags',
    defaultViews: ['LISTING_MAIN'],
    bulkEditable: true,
    bulkGroup: ProductFieldGroup.tags,
    bulkField: ProductBulkUpdateField.tags,
    bulkMethods: [
      BulkUpdateMethod.set,
      BulkUpdateMethod.unset,
      BulkUpdateMethod.push,
      BulkUpdateMethod.pull,
    ],
    fileEditable: true,
    fileField: 'tag',
    fileMethods: [
      FileUpdateMethod.set,
      FileUpdateMethod.unset,
      FileUpdateMethod.push,
      FileUpdateMethod.pull,
    ],
    filterOperators: [FilterOperator.equal, FilterOperator.exists],
    filterType: FilterType.simpleSelect,
    filterCollection: FilterCollection.tags,
  },
  {
    label: 'Tipologia',
    field: 'productType',
    defaultViews: ['LISTING_MAIN'],
    filterOperators: [FilterOperator.equal, FilterOperator.exists],
    filterType: FilterType.simpleSelect,
    filterOptions: [
      {
        value: ProductType.simple,
        label: 'Simple',
      },
      {
        value: ProductType.virtual,
        label: 'With variants',
      },
      {
        value: ProductType.kit,
        label: 'Kit',
      },
      {
        value: ProductType.package,
        label: 'Package',
      },
      {
        value: ProductType.service,
        label: 'Service',
      },
    ],
  },
  {
    label: 'Giacenza',
    field: 'onHandQty',
    defaultViews: ['LISTING_MAIN'],
    mappable: true,
    filterOperators: [
      FilterOperator.lessThan,
      FilterOperator.lessOrEqualThan,
      FilterOperator.moreThan,
      FilterOperator.moreOrEqualThan,
      FilterOperator.equal,
      FilterOperator.notEqual,
    ],
    filterType: FilterType.number,
  },
  {
    label: 'Disponibilità',
    field: 'availableQty',
    defaultViews: ['LISTING_MAIN'],
    filterOperators: [
      FilterOperator.lessThan,
      FilterOperator.lessOrEqualThan,
      FilterOperator.moreThan,
      FilterOperator.moreOrEqualThan,
      FilterOperator.equal,
      FilterOperator.notEqual,
    ],
    filterType: FilterType.number,
  },
  {
    label: 'Ordinato',
    field: 'incomingQty',
    defaultViews: ['LISTING_MAIN'],
    filterOperators: [
      FilterOperator.lessThan,
      FilterOperator.lessOrEqualThan,
      FilterOperator.moreThan,
      FilterOperator.moreOrEqualThan,
      FilterOperator.equal,
      FilterOperator.notEqual,
    ],
    filterType: FilterType.number,
  },
  {
    label: 'In arrivo',
    field: 'receivingQty',
    filterOperators: [
      FilterOperator.lessThan,
      FilterOperator.lessOrEqualThan,
      FilterOperator.moreThan,
      FilterOperator.moreOrEqualThan,
      FilterOperator.equal,
      FilterOperator.notEqual,
    ],
    filterType: FilterType.number,
  },
  {
    label: 'Invendibili',
    field: 'scrappedQty',
    filterOperators: [
      FilterOperator.lessThan,
      FilterOperator.lessOrEqualThan,
      FilterOperator.moreThan,
      FilterOperator.moreOrEqualThan,
      FilterOperator.equal,
    ],
    filterType: FilterType.number,
  },
  {
    label: "Prezzo d'acquisto",
    field: 'price.purchase',
    bulkEditable: true,
    mappable: true,
    completable: true,
    bulkGroup: ProductFieldGroup.prices,
    bulkField: ProductBulkUpdateField.pricePurchase,
    bulkMethods: [BulkUpdateMethod.set, BulkUpdateMethod.unset],
    fileEditable: true,
    fileField: 'price.purchase',
    fileMethods: [
      FileUpdateMethod.set,
      FileUpdateMethod.unset,
      FileUpdateMethod.add,
      FileUpdateMethod.subtract,
      FileUpdateMethod.multiply,
      FileUpdateMethod.divide,
    ],
    valuePerCatalog: true,
    isLocalizable: true,
    filterOperators: [
      FilterOperator.lessThan,
      FilterOperator.lessOrEqualThan,
      FilterOperator.moreThan,
      FilterOperator.moreOrEqualThan,
      FilterOperator.equal,
      FilterOperator.notEqual,
    ],
    filterType: FilterType.price,
  },
  {
    label: 'Prezzo di listino',
    field: 'price.listing',
    bulkEditable: true,
    mappable: true,
    completable: true,
    bulkGroup: ProductFieldGroup.prices,
    bulkField: ProductBulkUpdateField.priceListing,
    bulkMethods: [BulkUpdateMethod.set, BulkUpdateMethod.unset],
    fileEditable: true,
    fileField: 'price.listing',
    fileMethods: [
      FileUpdateMethod.set,
      FileUpdateMethod.unset,
      FileUpdateMethod.add,
      FileUpdateMethod.subtract,
      FileUpdateMethod.multiply,
      FileUpdateMethod.divide,
    ],
    valuePerCatalog: true,
    isLocalizable: true,
    filterOperators: [
      FilterOperator.lessThan,
      FilterOperator.lessOrEqualThan,
      FilterOperator.moreThan,
      FilterOperator.moreOrEqualThan,
      FilterOperator.equal,
      FilterOperator.notEqual,
    ],
    filterType: FilterType.price,
  },
  {
    label: 'Nome promozione',
    field: 'price.discount.name',
  },
  {
    label: 'Data inizio promozione',
    field: 'price.discount.startDate',
  },
  {
    label: 'Data fine promozione',
    field: 'price.discount.endDate',
  },
  {
    label: 'Prezzo promozione',
    field: 'price.discount',
    bulkEditable: true,
    completable: true,
    mappable: true,
    bulkGroup: ProductFieldGroup.prices,
    bulkField: ProductBulkUpdateField.priceDiscount,
    bulkMethods: [BulkUpdateMethod.set, BulkUpdateMethod.unset],
    fileEditable: true,
    fileField: 'price.discount',
    fileMethods: [
      FileUpdateMethod.set,
      FileUpdateMethod.unset,
      FileUpdateMethod.add,
      FileUpdateMethod.subtract,
      FileUpdateMethod.multiply,
      FileUpdateMethod.divide,
    ],
    valuePerCatalog: true,
    isLocalizable: true,
    filterOperators: [
      FilterOperator.lessThan,
      FilterOperator.lessOrEqualThan,
      FilterOperator.moreThan,
      FilterOperator.moreOrEqualThan,
      FilterOperator.equal,
      FilterOperator.notEqual,
    ],
    filterType: FilterType.price,
  },
  {
    label: '% IVA',
    field: 'taxRate',
    bulkEditable: true,
    mappable: true,
    completable: true,
    bulkGroup: ProductFieldGroup.prices,
    bulkField: ProductBulkUpdateField.taxRate,
    bulkMethods: [BulkUpdateMethod.set, BulkUpdateMethod.unset],
    fileEditable: true,
    fileField: 'taxRate',
    fileMethods: [FileUpdateMethod.set, FileUpdateMethod.unset],
    filterOperators: [
      FilterOperator.lessThan,
      FilterOperator.lessOrEqualThan,
      FilterOperator.moreThan,
      FilterOperator.moreOrEqualThan,
      FilterOperator.equal,
      FilterOperator.notEqual,
    ],
    filterType: FilterType.number,
  },
  {
    label: 'Scorta minima',
    field: 'minProvisions',
    bulkEditable: true,
    mappable: true,
    bulkGroup: ProductFieldGroup.logistic,
    bulkField: ProductBulkUpdateField.minProvisions,
    bulkMethods: [BulkUpdateMethod.set, BulkUpdateMethod.unset],
    fileEditable: true,
    fileField: 'minProvisions',
    fileMethods: [
      FileUpdateMethod.set,
      FileUpdateMethod.unset,
      FileUpdateMethod.add,
      FileUpdateMethod.subtract,
      FileUpdateMethod.multiply,
      FileUpdateMethod.divide,
    ],
    filterOperators: [
      FilterOperator.lessThan,
      FilterOperator.lessOrEqualThan,
      FilterOperator.moreThan,
      FilterOperator.moreOrEqualThan,
      FilterOperator.equal,
      FilterOperator.notEqual,
    ],
    filterType: FilterType.number,
  },
  {
    label: 'Scorta massima',
    field: 'maxProvisions',
    bulkEditable: true,
    mappable: true,
    bulkGroup: ProductFieldGroup.logistic,
    bulkField: ProductBulkUpdateField.maxProvisions,
    bulkMethods: [BulkUpdateMethod.set, BulkUpdateMethod.unset],
    fileEditable: true,
    fileField: 'maxProvisions',
    fileMethods: [
      FileUpdateMethod.set,
      FileUpdateMethod.unset,
      FileUpdateMethod.add,
      FileUpdateMethod.subtract,
      FileUpdateMethod.multiply,
      FileUpdateMethod.divide,
    ],
    filterOperators: [
      FilterOperator.lessThan,
      FilterOperator.lessOrEqualThan,
      FilterOperator.moreThan,
      FilterOperator.moreOrEqualThan,
      FilterOperator.equal,
      FilterOperator.notEqual,
    ],
    filterType: FilterType.number,
  },
  {
    label: 'Classificazione ABC',
    field: 'abcClass',
    bulkEditable: true,
    mappable: false,
    bulkGroup: ProductFieldGroup.logistic,
    bulkField: ProductBulkUpdateField.abcClass,
    bulkMethods: [BulkUpdateMethod.set, BulkUpdateMethod.unset],
    fileEditable: true,
    fileField: 'abcClass',
    fileMethods: [FileUpdateMethod.set, FileUpdateMethod.unset],
    filterOperators: [
      FilterOperator.exists,
      FilterOperator.notExists,
      FilterOperator.contains,
      FilterOperator.equal,
      FilterOperator.notEqual,
    ],
    filterType: FilterType.text,
  },
  {
    label: 'Qtà per confezione',
    field: 'packQty',
    bulkEditable: true,
    completable: true,
    mappable: true,
    bulkGroup: ProductFieldGroup.logistic,
    bulkField: ProductBulkUpdateField.packQty,
    bulkMethods: [BulkUpdateMethod.set, BulkUpdateMethod.unset],
    fileEditable: true,
    fileField: 'packageQuantity',
    fileMethods: [
      FileUpdateMethod.set,
      FileUpdateMethod.unset,
      FileUpdateMethod.add,
      FileUpdateMethod.subtract,
      FileUpdateMethod.multiply,
      FileUpdateMethod.divide,
    ],
    filterOperators: [
      FilterOperator.lessThan,
      FilterOperator.lessOrEqualThan,
      FilterOperator.moreThan,
      FilterOperator.moreOrEqualThan,
      FilterOperator.equal,
      FilterOperator.notEqual,
    ],
    filterType: FilterType.number,
  },
  {
    label: 'Qtà per pallet',
    field: 'palletQty',
    bulkEditable: true,
    completable: true,
    mappable: true,
    bulkGroup: ProductFieldGroup.logistic,
    bulkField: ProductBulkUpdateField.palletQty,
    bulkMethods: [BulkUpdateMethod.set, BulkUpdateMethod.unset],
    fileEditable: true,
    fileField: 'palletQty',
    fileMethods: [
      FileUpdateMethod.set,
      FileUpdateMethod.unset,
      FileUpdateMethod.add,
      FileUpdateMethod.subtract,
      FileUpdateMethod.multiply,
      FileUpdateMethod.divide,
    ],
    filterOperators: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.lessThan,
      FilterOperator.lessOrEqualThan,
      FilterOperator.moreThan,
      FilterOperator.moreOrEqualThan,
    ],
    filterType: FilterType.number,
  },
  {
    label: 'Larghezza (cm)',
    field: 'volume.w',
    mappable: true,
    completable: true,
    fileEditable: true,
    fileField: 'width',
    fileMethods: [
      FileUpdateMethod.set,
      FileUpdateMethod.unset,
      FileUpdateMethod.add,
      FileUpdateMethod.subtract,
      FileUpdateMethod.multiply,
      FileUpdateMethod.divide,
    ],
    filterOperators: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.lessThan,
      FilterOperator.lessOrEqualThan,
      FilterOperator.moreThan,
      FilterOperator.moreOrEqualThan,
    ],
    filterType: FilterType.number,
  },
  {
    label: 'Lunghezza (cm)',
    field: 'volume.l',
    mappable: true,
    completable: true,
    fileEditable: true,
    fileField: 'length',
    fileMethods: [
      FileUpdateMethod.set,
      FileUpdateMethod.unset,
      FileUpdateMethod.add,
      FileUpdateMethod.subtract,
      FileUpdateMethod.multiply,
      FileUpdateMethod.divide,
    ],
    filterOperators: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.lessThan,
      FilterOperator.lessOrEqualThan,
      FilterOperator.moreThan,
      FilterOperator.moreOrEqualThan,
    ],
    filterType: FilterType.number,
  },
  {
    label: 'Altezza (cm)',
    field: 'volume.h',
    completable: true,
    mappable: true,
    fileEditable: true,
    fileField: 'height',
    fileMethods: [
      FileUpdateMethod.set,
      FileUpdateMethod.unset,
      FileUpdateMethod.add,
      FileUpdateMethod.subtract,
      FileUpdateMethod.multiply,
      FileUpdateMethod.divide,
    ],
    filterOperators: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.lessThan,
      FilterOperator.lessOrEqualThan,
      FilterOperator.moreThan,
      FilterOperator.moreOrEqualThan,
    ],
    filterType: FilterType.number,
  },
  {
    label: 'Volume (mc)',
    field: 'volume.total',
    completable: true,
    mappable: true,
    bulkEditable: true,
    bulkGroup: ProductFieldGroup.logistic,
    bulkField: ProductBulkUpdateField.volume,
    bulkMethods: [BulkUpdateMethod.set, BulkUpdateMethod.unset],
    fileEditable: true,
    fileField: 'volume',
    fileMethods: [
      FileUpdateMethod.set,
      FileUpdateMethod.unset,
      FileUpdateMethod.add,
      FileUpdateMethod.subtract,
      FileUpdateMethod.multiply,
      FileUpdateMethod.divide,
    ],
    filterOperators: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.lessThan,
      FilterOperator.lessOrEqualThan,
      FilterOperator.moreThan,
      FilterOperator.moreOrEqualThan,
    ],
    filterType: FilterType.number,
  },
  {
    label: 'Peso netto (kg)',
    field: 'netWeight',
    bulkEditable: true,
    completable: true,
    mappable: true,
    bulkGroup: ProductFieldGroup.logistic,
    bulkField: ProductBulkUpdateField.netWeight,
    bulkMethods: [BulkUpdateMethod.set, BulkUpdateMethod.unset],
    fileEditable: true,
    fileField: 'netWeight',
    fileMethods: [
      FileUpdateMethod.set,
      FileUpdateMethod.unset,
      FileUpdateMethod.add,
      FileUpdateMethod.subtract,
      FileUpdateMethod.multiply,
      FileUpdateMethod.divide,
    ],
    filterOperators: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.lessThan,
      FilterOperator.lessOrEqualThan,
      FilterOperator.moreThan,
      FilterOperator.moreOrEqualThan,
    ],
    filterType: FilterType.number,
  },
  {
    label: 'Peso lordo (kg)',
    field: 'grossWeight',
    bulkEditable: true,
    completable: true,
    mappable: true,
    bulkGroup: ProductFieldGroup.logistic,
    bulkField: ProductBulkUpdateField.grossWeight,
    bulkMethods: [BulkUpdateMethod.set, BulkUpdateMethod.unset],
    fileEditable: true,
    fileField: 'grossWeight',
    fileMethods: [
      FileUpdateMethod.set,
      FileUpdateMethod.unset,
      FileUpdateMethod.add,
      FileUpdateMethod.subtract,
      FileUpdateMethod.multiply,
      FileUpdateMethod.divide,
    ],
    filterOperators: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.lessThan,
      FilterOperator.lessOrEqualThan,
      FilterOperator.moreThan,
      FilterOperator.moreOrEqualThan,
    ],
    filterType: FilterType.number,
  },
  {
    label: 'Note',
    field: 'notes',
    fileEditable: true,
    fileField: 'notes',
    fileMethods: [FileUpdateMethod.set, FileUpdateMethod.unset],
    defaultViews: ['LISTING_MAIN'],
    filterOperators: [FilterOperator.equal, FilterOperator.notEqual],
    filterType: FilterType.text,
  },
  {
    label: 'Descrizione logistica',
    field: 'logisticsDescription',
    mappable: true,
    fileEditable: true,
    fileMethods: [FileUpdateMethod.set, FileUpdateMethod.unset],
    filterOperators: [FilterOperator.equal, FilterOperator.notEqual],
    filterType: FilterType.text,
  },
  {
    label: 'Disponibilità infinita',
    field: 'infiniteStocks',
    bulkEditable: true,
    mappable: true,
    bulkGroup: ProductFieldGroup.logistic,
    bulkField: ProductBulkUpdateField.infiniteStocks,
    bulkMethods: [BulkUpdateMethod.set],
    fileEditable: true,
    fileField: 'infiniteStock',
    fileMethods: [FileUpdateMethod.set, FileUpdateMethod.unset],
    filterOperators: [FilterOperator.equal],
    filterType: FilterType.boolean,
  },
  {
    label: 'Drop ship',
    field: 'isDropShip',
    bulkEditable: true,
    bulkGroup: ProductFieldGroup.logistic,
    bulkField: ProductBulkUpdateField.dropship,
    bulkMethods: [BulkUpdateMethod.set],
    fileEditable: true,
    fileField: 'dropship',
    fileMethods: [FileUpdateMethod.set, FileUpdateMethod.unset],
    filterOperators: [FilterOperator.equal],
    filterType: FilterType.boolean,
  },
  {
    label: 'Inventario',
    field: 'stockCountId',
  },
  {
    label: 'Gestione lotti',
    field: 'batchManagement.enabled',
    bulkEditable: true,
    bulkGroup: ProductFieldGroup.logistic,
    bulkField: ProductBulkUpdateField.batchManagement,
    bulkMethods: [BulkUpdateMethod.set, BulkUpdateMethod.unset],
    filterOperators: [FilterOperator.equal],
    filterType: FilterType.boolean,
  },
  {
    label: 'Descrizione doganale',
    field: 'customsInfo.description',
    fileEditable: true,
    fileField: 'customsDescription',
    fileMethods: [FileUpdateMethod.set, FileUpdateMethod.unset],
    filterOperators: [FilterOperator.equal, FilterOperator.notEqual],
    filterType: FilterType.text,
  },
  {
    label: 'Codice HS',
    field: 'customsInfo.hsCode',
    fileEditable: true,
    fileField: 'customsHsCode',
    fileMethods: [FileUpdateMethod.set, FileUpdateMethod.unset],
    filterOperators: [FilterOperator.equal, FilterOperator.notEqual],
    filterType: FilterType.text,
  },
  {
    label: "Paese d'origine",
    field: 'customsInfo.originCountry',
    fileEditable: true,
    fileField: 'customsOriginCountry',
    fileMethods: [FileUpdateMethod.set, FileUpdateMethod.unset],
    filterOperators: [FilterOperator.equal, FilterOperator.notEqual],
    filterType: FilterType.text,
  },
  {
    label: 'Attributi',
    field: 'attributes',
    bulkEditable: true,
    bulkGroup: ProductFieldGroup.attributes,
    bulkField: ProductBulkUpdateField.attribute,
  },
  {
    label: 'Famiglia',
    field: 'family.code',
    bulkEditable: false,
    filterOperators: [
      FilterOperator.equal,
      FilterOperator.notEqual,
      FilterOperator.exists,
    ],
    filterType: FilterType.simpleSelect,
    filterCollection: FilterCollection.families,
  },
  {
    label: '% Completamento',
    field: 'family.complete',
    bulkEditable: false,
    filterOperators: [
      FilterOperator.lessThan,
      FilterOperator.lessOrEqualThan,
      FilterOperator.moreThan,
      FilterOperator.moreOrEqualThan,
      FilterOperator.equal,
      FilterOperator.notEqual,
    ],
    filterType: FilterType.number,
  },
  {
    label: 'Foto',
    field: 'files',
    completable: true,
    filterOperators: [FilterOperator.exists],
    filterType: FilterType.boolean,
  },
  {
    label: 'Ubicazioni predefinite',
    field: 'sites',
    bulkEditable: true,
    bulkMethods: [BulkUpdateMethod.refresh],
    bulkGroup: ProductFieldGroup.sites,
    bulkField: ProductBulkUpdateField.sites,
  },
]

export const PRODUCT_TABS = [
  {
    code: ProductTab.general,
    name: 'Generale',
  },
  {
    code: ProductTab.attributes,
    name: 'Attributi',
  },
  {
    code: ProductTab.variants,
    name: 'Varianti',
    types: [ProductType.simple, ProductType.virtual],
  },
  {
    code: ProductTab.logistic,
    name: 'Logistica',
  },
  {
    code: ProductTab.photos,
    name: 'Foto',
  },
  {
    code: ProductTab.categories,
    name: 'Categorie',
  },
  {
    code: ProductTab.classifications,
    name: 'Classificazioni',
  },
  {
    code: ProductTab.prices,
    name: 'Prezzi',
  },
  {
    code: ProductTab.kit,
    name: 'Kit',
    types: [ProductType.kit],
  },
]

export const PRODUCT_IMPORT_KEYS = [
  {
    name: '_id',
    key: ProductImportKey.id,
  },
  {
    name: 'SKU',
    key: ProductImportKey.sku,
  },
  {
    name: 'Barcode',
    key: ProductImportKey.barcode,
  },
]

export const PRODUCT_SKU_TYPES: SelectionData<ProductSkuType>[] = [
  {
    label: 'Numerico',
    value: ProductSkuType.numeric,
    icon: 'fad fa-sort-numeric-down',
  },
  {
    label: 'Codificato',
    value: ProductSkuType.coded,
    icon: 'fad fa-font-case',
  },
]

export const PRODUCT_ORDERABLE_TYPES = [
  ProductType.kit,
  ProductType.service,
  ProductType.simple,
]

// Initial states

export const PRODUCT_FAMILY_INITIAL_STATE: ProductFamilySettings = {
  code: undefined,
  complete: [],
  fields: [],
  attributes: [],
}

export const PRODUCT_INITIAL_STATE: Partial<Product> = {
  SKU: undefined,
  externalSKUs: [],
  barcodes: [],
  name: [CATALOG_DEFAULT_VALUE],
  shortDescription: [CATALOG_DEFAULT_EMPTY_STRING_VALUE],
  description: [CATALOG_DEFAULT_EMPTY_STRING_VALUE],
  productType: ProductType.simple,
  status: ProductStatus.enabled,
  categories: [],
  classifications: [],
  family: PRODUCT_FAMILY_INITIAL_STATE,
  suppliers: [],
  tags: [],
  volume: {},
  attributes: [],
  files: [],
  batchManagement: {
    enabled: false,
  },
  price: {
    listing: [],
    purchase: [],
    discount: [],
  },
  onHandQty: 0,
  availableQty: 0,
  incomingQty: 0,
  receivingQty: 0,
  locations: [],
  warehouses: [],
  sales: [],
  customsInfo: {},
  variantsManagement: {
    childrenIds: [],
    attributeIds: [],
  },
}

export const PRODUCT_MOVEMENT_INITIAL_STATE: Partial<ProductMovement> = {
  locationId: undefined,
  warehouseId: undefined,
  quantity: 0,
  action: ReasonType.increase,
  reasonId: undefined,
}

export const PRODUCT_SUPPLIER_INITIAL_STATE: ProductSupplier = {
  supplierId: undefined,
  price: {
    purchase: 0,
    net: 0,
    discounts: [0, 0, 0, 0, 0],
    promotion: {},
  },
  moq: 0,
  lotQty: 1,
  currency: 'EUR',
}

export const PRODUCT_SCOPE_INITIAL_STATE: ProductScope = {
  catalogCode: 'default',
  locale: 'default',
}

export const PRODUCT_BULK_UPDATE_INITIAL_STATE: ProductBulkUpdate = {
  catalogCode: undefined,
  locale: undefined,
  filter: {},
  count: 0,
  mode: undefined,
  subject: undefined,
  value: undefined,
}

export const PRODUCT_CONFIGURATION_INITIAL_STATE: Partial<ProductConfiguration> =
  {
    product: PRODUCT_INITIAL_STATE,
    attributes: [],
    options: [],
    sku: {
      type: undefined,
    },
    name: {},
    variants: [],
  }

export const PRODUCT_PROVISIONING_SETTINGS_INITIAL_STATE: ProductProvisioningSettings =
  {
    useIncomingQty: true,
    useReceivingQty: false,
    useMaxProvisions: false,
    useMinProvisions: false,
    includeDropShipping: false,
  }

export const PRODUCT_EXPORT_STOCK_DATA_INITIAL_STATE: ProductExportStockData = {
  warehouseId: undefined,
  from: new Date(),
  to: new Date(),
  email: '',
}

export const PRODUCT_HISTORIC_STOCK_DATA_INITIAL_STATE: ProductHistoricStockData =
  {
    warehouseId: undefined,
    date: new Date().toISOString(),
  }

export const PRODUCT_BARCODE_INITIAL_STATE: ProductBarcode = {
  type: ProductBarcodeType.ean13,
  value: '',
}

export const PRODUCT_PARAMS: ViewTargetParam[] = [
  {
    name: 'Catalog',
    param: 'catalogCode',
    paramType: FilterType.simpleSelect,
    paramCollection: FilterCollection.catalogs,
  },
  {
    name: 'Warehouse',
    param: 'warehouseId',
    paramType: FilterType.simpleSelect,
    paramCollection: FilterCollection.warehouses,
  },
  {
    name: 'Fornitore',
    param: 'supplierId',
    paramType: FilterType.simpleSelect,
    paramCollection: FilterCollection.suppliers,
  },
]
