import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import {
  EMPTY,
  filter,
  map,
  Observable,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs'
import {
  View,
  ViewData,
  ViewExternalData,
  ViewTargetParam,
} from '../view.model'
import { Attribute } from '../../attributes'
import { ViewsRepository } from '../views.repository'
import { getViewTargetParams, initView } from '../libs/view.lib'
import { ViewsService } from '../views.service'

interface ViewDetailState {
  viewId?: string
  view?: View
  attributes?: Attribute[]
  extData?: ViewExternalData[]
  fields?: string[]
  params?: ViewTargetParam[]
  error?: HttpErrorResponse
  toClose: boolean
  toReload: boolean
  isLoading: boolean
  isModified: boolean
}

const VIEW_DETAIL_INITIAL_STATE: ViewDetailState = {
  toClose: false,
  toReload: false,
  isLoading: false,
  isModified: false,
}

@Injectable()
export class ViewDetailUseCase extends ComponentStore<ViewDetailState> {
  constructor(
    private viewsRepository: ViewsRepository,
    private viewsService: ViewsService,
  ) {
    super(VIEW_DETAIL_INITIAL_STATE)
  }

  // Selectors

  readonly selectState$: Observable<ViewDetailState> = this.select(
    (state) => state,
  )

  readonly selectAttributes$: Observable<Attribute[] | undefined> = this.select(
    (state) => state.attributes,
  )

  readonly selectFields$: Observable<string[] | undefined> = this.select(
    (state) => state.fields,
  )

  readonly selectParams$: Observable<ViewTargetParam[] | undefined> =
    this.select((state) => state.params)

  readonly selectExternalData$: Observable<ViewExternalData[] | undefined> =
    this.select((state) => state.extData)

  readonly selectIsModified$: Observable<boolean> = this.select(
    (state) => state.isModified,
  )

  readonly selectIsLoading$: Observable<boolean> = this.select(
    (state) => state.isLoading,
  )

  readonly selectIsErrored$: Observable<boolean> = this.select(
    (state) => !!state.error,
  )

  readonly selectToReload$: Observable<boolean> = this.select(
    (state) => state.toReload,
  )

  readonly selectToClose$: Observable<boolean> = this.select(
    (state) => state.toClose,
  )

  readonly selectView$: Observable<View | undefined> =
    this.selectIsLoading$.pipe(
      filter((isLoading) => !isLoading),
      switchMap(() => this.select((state) => state.view)),
    )

  // Effects

  readonly init$ = this.effect(
    (view$: Observable<Partial<View> | undefined>) => {
      return view$.pipe(
        map((view) => initView(view)),
        tap((view) => this.setView(view)),
      )
    },
  )

  readonly read$ = this.effect((viewId$: Observable<string>) => {
    return viewId$.pipe(
      tap((viewId) => this.setViewId(viewId)),
      switchMap((viewId) =>
        this.viewsRepository.loadViewData$(viewId).pipe(
          tapResponse(
            (viewData) => this.setViewData(viewData),
            (error: HttpErrorResponse) => this.setError(error),
          ),
        ),
      ),
    )
  })

  readonly save$ = this.effect((toClose$: Observable<boolean>) => {
    return toClose$.pipe(
      tap(() => this.setIsLoading(true)),
      switchMap((toClose) =>
        this.selectView$.pipe(
          take(1),
          switchMap((view) => {
            if (!view) {
              return EMPTY
            }

            return this.viewsService.upsert$(view).pipe(
              tapResponse(
                (view) => {
                  this.viewsRepository.notify({ kind: 'VIEW_SAVED' })
                  this.setViewSaved({ view, toClose })
                },
                (error: HttpErrorResponse) => this.setError(error),
              ),
            )
          }),
        ),
      ),
    )
  })

  readonly delete$ = this.effect((void$: Observable<void>) => {
    return void$.pipe(
      withLatestFrom(this.selectView$),
      switchMap(([_, view]) => {
        if (!view) {
          return EMPTY
        }

        return this.viewsRepository.showDeleteDialog$(view).pipe(
          switchMap((confirmation) => {
            if (!confirmation) {
              return EMPTY
            }

            this.setIsLoading(true)
            return this.viewsService.delete$(view._id).pipe(
              tapResponse(
                () => {
                  this.viewsRepository.notify({
                    kind: 'VIEW_DELETED',
                  })
                  this.setFlags({
                    toClose: true,
                    toReload: true,
                    isLoading: false,
                  })
                },
                (error: HttpErrorResponse) => this.setError(error),
              ),
            )
          }),
        )
      }),
    )
  })

  // Reducers

  readonly setViewId = this.updater(
    (state, viewId: string): ViewDetailState => ({
      viewId,
      toClose: false,
      toReload: false,
      isLoading: true,
      isModified: false,
    }),
  )

  readonly setIsLoading = this.updater(
    (state, isLoading: boolean): ViewDetailState => {
      return {
        ...state,
        isLoading,
      }
    },
  )

  readonly setViewData = this.updater(
    (state, viewData: ViewData): ViewDetailState => ({
      ...state,
      view: viewData.view,
      attributes: viewData.attributes,
      params: getViewTargetParams(viewData.view.target),
      extData: viewData.externalData,
      isLoading: false,
      isModified: false,
    }),
  )

  readonly setView = this.updater(
    (state, view: View): ViewDetailState => ({
      ...state,
      view,
      params: getViewTargetParams(view.target),
      isLoading: false,
      isModified: false,
    }),
  )

  readonly setViewSaved = this.updater(
    (state, params: { view: View; toClose?: boolean }): ViewDetailState => ({
      ...state,
      view: params.view,
      toClose: params.toClose || false,
      isLoading: false,
      isModified: false,
    }),
  )

  readonly updateView = this.updater(
    (state, view: View): ViewDetailState => ({
      ...state,
      view,
      params: getViewTargetParams(view.target),
      isLoading: false,
      isModified: true,
    }),
  )

  readonly setError = this.updater(
    (state, error: HttpErrorResponse): ViewDetailState => ({
      ...state,
      error,
      isLoading: false,
    }),
  )

  readonly addAttribute = this.updater(
    (state, attribute: Attribute): ViewDetailState => ({
      ...state,
      attributes: [...(state.attributes || []), attribute],
    }),
  )

  readonly addExternalData = this.updater(
    (state, extData: ViewExternalData): ViewDetailState => ({
      ...state,
      extData: [...(state.extData || []), extData],
    }),
  )

  readonly setFlags = this.updater(
    (
      state,
      flags: { toClose?: boolean; toReload?: boolean; isLoading?: boolean },
    ): ViewDetailState => {
      return {
        ...state,
        ...flags,
      }
    },
  )
}
